body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.brandText {
  margin-left: 8px;
  font-size: 24px;
  font-weight: bold;
  color: #002fff;
  text-decoration: dashed;
  text-align: center;
}
video {
  width: 480px;
  height: 360px;
}
